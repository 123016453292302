import React, { Component } from 'react';

class HomePage extends Component {
  render() {
    return (
      <div class="home">
        <h1 class="title">Readify</h1>
        <p align="center">
          <iframe
                src={`https://www.youtube.com/embed/1eaHmB-muX8`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Readify help"
              />
        </p>
        
        <div class="container">
          <div class="center">
            <a href='https://play.google.com/store/apps/details?id=org.jadder.readify'><img class="google" alt='Get it on Google Play' src='google.png'/></a>
            <a href='https://apps.apple.com/au/app/readify/id6478643928'><img class="apple" alt='Get it on IOS' src='apple.png'/></a>
          </div>
        </div>  
      </div>
    )
  }
}

export default HomePage;