import React, { Component } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify'

class Contact extends Component {

  constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          feedback: '',
          captcha: '',
          error: ''
        }
  }

  componentDidMount () {
    loadCaptchaEnginge(6); 
  };
      
  render() {
    return (
      <div class="home">
       <h1 class="title">Contact</h1>
       <div class="form-center">
        <form
          className="ui form"
          id={this.props.id}
          name={this.props.name}
          method={this.props.method}
          action={this.props.action}
        >
          <textarea
            id="name"
            name="name"
            onChange={this.handleInputChange.bind(this)}
            placeholder="your name"
            required
            value={this.state.name}
            style={{ width: "100%" }}
            rows={1}
          />
          <br />
          <textarea
            id="email"
            name="email"
            onChange={this.handleInputChange.bind(this)}
            placeholder="your email address"
            required
            value={this.state.email}
            error={this.state.error}
            style={{ width: "100%" }}
            rows={1}
          />
          <br />
          <textarea
            id="feedback"
            name="feedback"
            onChange={this.handleInputChange.bind(this)}
            placeholder="what is your enquiry?"
            required
            value={this.state.feedback}
            style={{ width: "100%", height: "250px" }}
          />
          <br />
          <div>
            <LoadCanvasTemplate />
            <textarea
             id="captcha"
              name="captcha"
              onChange={this.handleInputChange.bind(this)}
              placeholder="enter captcha"
              required
              value={this.state.captcha}
              style={{ width: "100%" }}
              rows={1}
          />
          </div> 
          <br />
          <input
            type="button"
            value="Send"
            className="ui button"
            style={{
              fontFamily: "Amatic SC",
              fontSize: "20px",
              color: "blue"
            }}
            onClick={this.sendMessage.bind(this)}
          />
        </form>
      </div>
    </div>
    )
  }


  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }

  validateMail(){
    return this.state.email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  sendMessage(event) {
    event.preventDefault();
    let user_captcha_value = this.state.captcha;
 
    if (validateCaptcha(user_captcha_value)) {
        if (!this.validateMail()) {
          toast.error("Invalid Email address") 
          return;
        }
        const templateParams = {
            from_name: this.state.name + " (" + this.state.email + ")",
            to_name: "jadderdevelopmenthouse@gmail.com",
            message: this.state.feedback
        };
      
        try{
          emailjs
          .send('service_feqvaeg', 'template_u29kvhm', templateParams, {
            publicKey: 'iE-m11nrlinr-Y8DL',
          }).then( 
                alert("Email messaage successfully sent")
            )      
        } catch(err){
          toast.error("Issue sending email", err)
        }   
    } else {
        alert("Error with validating captcha")
    }
  }
}

export default Contact;