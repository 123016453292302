import React, { Component } from 'react';
class Footer extends Component {
  render() {
    return (
      <footer>
        <div>
          <h5>All rights reserved, Copyright 2024, Powered by Jadder Development House</h5>
        </div>
      </footer>
    )
  }
}

export default Footer;