import React, { Component } from 'react';

class FAQ extends Component {
  render() {
    return (
        <div class="home">
          <div>
            <h1 class="title">Frequently Asked Questions</h1>
            <h2>What does the subscription give me access to?</h2>
            <p>The ability to create and play stories as well as exporting stories to other devices</p>
            <h2>What are the terms of the subscription</h2>
            <p> The details can be found in the <a href="/terms" class="link">Terms of Service</a></p>
            <h2>What languages are supported?</h2>
            <p>Only English.</p>
            <h2>Why can't I sound out words and voice recognition doesn't detect?</h2>
            <p>Unfortunately the software at this stage will only detect fluent speaking, there are plans to include phonics.</p>
            <h2>Can I edit stories/quizzes that come with the app?</h2>
            <p>No.</p> 
            <h2>How many profiles can I make?</h2>
            <p>As many as required, but there a two classes of profile, Teacher (with the ability to create stories if subscribed) and Student</p>
            <h2>How do I contact Readify Support if I have an issue?</h2>
            <p><a href="/contact" class="link">Use the contact page </a></p>  
          </div>  
          <div>
            <h1 class="subtitle">Video Guides</h1>
            <h2>How do I create a profile?</h2>
            <p align="center">
                <iframe
                    src={`https://www.youtube.com/embed/erULc3ogZfQ`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Readify help - create profile"
                    />
            </p>  
            <h2>How do I create/edit a story/quiz?</h2>
            <p align="center">
                <iframe
                    src={`https://www.youtube.com/embed/RMfyy8uK2HE`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Readify help - create/edit story"
                    />
            </p>
            <h2>How do I play a story/quiz?</h2>
            <p align="center">
                <iframe
                    src={`https://www.youtube.com/embed/sU2fj25CJLs`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Readify help - playing story"
                    />
            </p>
          </div>
        </div>    
    )

  }
}

export default FAQ;