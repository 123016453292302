import React, { Component } from 'react';

class Terms extends Component {
  render() {
    return (
      <div>
        <h1 className="title">Terms of Service</h1>
        <h2>Is the App Free?</h2>
        <p>Currently the app is free to download and use the complimentary stories with a 7 day trial on creating your own stories.</p>
        <h2>What features unlock when I subscribe?</h2>
        <p>The ability to create your own stories and exporting them to other devices </p>
        <h2>What is the terms of the subscription</h2>
        <p>Either a monthly or yearly subscription</p>
        <p><a href="https://policies.google.com/terms?hl=en-US">Google End User Licence Agreement</a></p>
        <p><a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">Apple End User Licence Agreement</a></p>
        <h2>What is the price of the subscription?</h2>
        <i>International exchange rates and local tax rates apply.</i>
        <p>$1.99 AUD for a monthly subscription</p> 
        <p>OR</p>
        <p>$19.99 AUD for a yearly subscription which is a 16% discount on the monthly subcription</p>.
        <h2>What if i cancel my subscription?</h2>
        <p>Your access to the stories you have created won't be accessible, but the default stories provided with the app will be.</p>
        <h2>Can I restore my subscription and will i get access to my stories again</h2>
        <p>Yes.</p>
        <h2>What about copyrighted material</h2>
        <p>As the user you are required to not use copyrighted material without consent, if consent is not provided and still used, prosecution may result.</p>
        <h2>What about my data?</h2>
        <p>You are responsible for your own mobile data limits when using the app, ie:  clicking links to external websites will use data.</p>
      </div>
    )
  }
}

export default Terms;